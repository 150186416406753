<template>
  <div>
    <b-dropdown
      v-if="value == profileVerificationStatus.SUBMITTED"
      size="sm"
      :text="$t(`enumProfileVerificationStatus.${value}`)"
      :variant="statusColor(value)"
      :disabled="!checkAccessRight(module.ACCOUNT_VERIFICATION, [action.ACCOUNT_VERIFICATION_UPDATE])"
    >
      <template v-for="(item, index) in availableUpdateStatus">
        <b-dropdown-item
          :key="index"
          class="d-flex align-center cursor-pointer"
          @click="openUpdateStatusModal(item.key)"
        >
          <label
            class="label label-lg label-inline cursor-pointer w-100 py-5"
            :class="`label-light-${statusColor(item.key)}`"
          >
            {{ $t(`label.${item.label}`) }}</label
          >
        </b-dropdown-item>
      </template>
    </b-dropdown>
    <b-button
      v-else
      size="sm"
      :variant="statusColor(value)"
      class="text-capitalize cursor-default"
    >
      {{ value }}
    </b-button>
    <b-modal
      v-model="modal"
      :title="$t('label.updateAccountVerification')"
      centered
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      header-class="text-capitalize"
      @cancel="cancelAction"
      @ok="okAction"
    >
      <div v-if="selectedStatus == profileVerificationStatus.APPROVED">
        {{
          $t('message.requestUpdateAccountVerificationStatusMessage', [
            $t('label.approve').toLowerCase()
          ])
        }}
      </div>
      <div v-else-if="selectedStatus == profileVerificationStatus.REJECTED">
        <div class="mb-4">
          {{
            $t('message.requestUpdateAccountVerificationStatusMessage', [
              $t('label.reject').toLowerCase()
            ])
          }}
        </div>
        <AppFormField
          v-model="$v.remark.$model"
          :state="validateState('remark')"
          :validationValue="$v.remark"
          :title="$t('label.remark')"
          :solid="true"
          :validators="['required']"
        ></AppFormField>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import AppFormField from '@/views/components/bases/form-field/AppFormField';
  import { ProfileVerificationStatus } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import { MODULE, ACTION } from '@/core/constants';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    USER_UPDATE_ACCOUNT_VERIFICATION,
    USER_INITIAL_UPDATE_ACCOUNT_VERIFICATION_STATE
  } from '@/core/store/user.module';

  export default {
    name: 'AccountVerificationStatus',
    mixins: [commonMixin, validationMixin],
    components: {
      AppFormField
    },
    props: {
      id: {
        type: Number
      },
      value: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      profileVerificationStatus: ProfileVerificationStatus,
      availableUpdateStatus: [
        {
          key: ProfileVerificationStatus.APPROVED,
          label: 'approve'
        },
        {
          key: ProfileVerificationStatus.REJECTED,
          label: 'reject'
        }
      ],
      module: MODULE,
      action: ACTION,
      moduleName: '',
      modal: false,
      selectedStatus: null,
      remark: null
    }),
    computed: {
      updateAccountVerificationComplete() {
        return this.$store.state.user.updateAccountVerification.complete;
      }
    },
    watch: {
      updateAccountVerificationComplete(value) {
        let response = this.$store.state.user.updateAccountVerification;
        let title = i18nHelper.getMessage('label.updateAccountVerification');
        let initialStateAction = USER_INITIAL_UPDATE_ACCOUNT_VERIFICATION_STATE;
        let successAction = () => {
          this.updateAccountVerificationCompleteAction();
        };

        if (value) {
          this.actionUpdateRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    methods: {
      updateAccountVerificationCompleteAction() {
        this.modal = false;
        this.$emit('update-account-verification-success');
      },
      cancelAction() {
        this.selectedStatus = null;
        this.remark = null;
        this.modal = false;
        this.$v.$reset();
      },
      okAction(event) {
        event.preventDefault();
        if (this.selectedStatus == ProfileVerificationStatus.REJECTED) {
          if (this.validationCheck('remark')) {
            this.selectStatus();
          }
        } else {
          this.selectStatus();
        }
      },
      selectStatus() {
        let data = {
          verificationStatus: this.selectedStatus
        };

        if (this.selectedStatus == ProfileVerificationStatus.REJECTED) {
          data.verificationRemark = this.remark;
        }

        this.updateAccountVerification(this.id, data);
      },
      openUpdateStatusModal(value) {
        this.selectedStatus = value;
        this.modal = true;
      },
      statusColor(value) {
        let color = {
          [ProfileVerificationStatus.PENDING]: 'primary',
          [ProfileVerificationStatus.SUBMITTED]: 'warning',
          [ProfileVerificationStatus.APPROVED]: 'success',
          [ProfileVerificationStatus.REJECTED]: 'danger',
          approve: 'success',
          reject: 'danger'
        };

        return color[value];
      },
      updateAccountVerification(id, data) {
        this.$store.dispatch(USER_UPDATE_ACCOUNT_VERIFICATION, { id, data });
      }
    },
    validations: {
      remark: {
        required
      }
    }
  };
</script>

<style></style>
