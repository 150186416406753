<template>
  <b-card v-if="value.profile">
    <template #header>
      <h3 class="text-capitalize">
        {{ $t('label.supportingDocuments') }}
      </h3>
    </template>

    <div
      v-if="['talent_agency', 'production_corporate_agency'].includes(userRole)"
    >
      <div v-if="value.profile.verificationFile">
        <template v-for="(item, index) in value.profile.verificationFile">
          <div
            :key="index"
            class="d-flex justify-content-between align-items-center"
          >
            <div>
              <i class="flaticon-attachment mr-4"></i>
              <span class="mb-0 text-capitalize">{{
                `${$t('label.document')} ${index + 1}`
              }}</span>
            </div>

            <AppButtonDocumentView
              :attachmentUrl="item.mediaPath"
            ></AppButtonDocumentView>
          </div>
        </template>
      </div>
      <div v-else>
        {{ $t('message.thereAreNoDocumentsToShow') }}
      </div>
    </div>
    <div v-else>
      <b-row no-gutters>
        <b-col cols="6" class="pr-4">
          <div>
            <h4 class="text-capitalize">
              {{ $t('label.gestureImage') }}
            </h4>
            <AppMediaViewer
              v-if="value.profile.gestureImage"
              :value="value.profile.gestureImage"
              class="bg-secondary"
            >
            </AppMediaViewer>

            <div v-else>{{ $t('message.thereAreNoImagesToShow') }}</div>
          </div>
        </b-col>
        <b-col cols="6" class="pl-4">
          <div>
            <h4 class="text-capitalize">
              {{ $t('label.userUploaded') }}
            </h4>
            <AppMediaViewer
              v-if="value.profile.verificationFile"
              :value="value.profile.verificationFile[0]"
            >
            </AppMediaViewer>

            <div v-else>{{ $t('message.thereAreNoImagesToShow') }}</div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
  import AppButtonDocumentView from '@/views/components/bases/button/AppButtonDocumentView';
  import AppMediaViewer from '@/views/components/bases/AppMediaViewer';

  export default {
    name: 'AccountVerificationSupportingDocumentsCard',
    components: {
      AppButtonDocumentView,
      AppMediaViewer
    },
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      userRole() {
        return this.value.role
          ? this.value.role
              .toLowerCase()
              .replace(' ', '_')
              .replace('/', '_')
              .replace('/', '_')
          : null;
      }
    }
  };
</script>

<style></style>
