<template>
  <b-card>
    <div class="d-flex mb-8">
      <div @click="onProfileImageOpen">
        <b-avatar square size="72px" :src="profileImage"></b-avatar>
      </div>
      <div class="p-4">
        <div class="font-weight-bold h3">
          <AppFeatureDetailsRelatedButton
            :value="{
              id: accountVerificationObj.id,
              name: accountVerificationObj.fname
            }"
            :toDetailsRouteName="routeName.PROFILE_DETAILS_DETAILS"
          ></AppFeatureDetailsRelatedButton>
        </div>
        <div class="font-size-sm text-gray-600">
          {{ accountVerificationObj.role }}
        </div>
      </div>
    </div>
    <div class="mb-8">
      <template v-for="(i, index) in basicInfoList">
        <div :key="i + index" class="mb-3 d-flex justify-content-between">
          <div class="font-weight-bolder text-capitalize">
            {{ $t(`label.${i.name}`) }}:
          </div>
          <div class="text-end text-gray-600">
            <div v-if="i.field == 'profile' && accountVerificationObj.profile">
              <div v-if="i.key == 'verificationStatus'">
                <AccountVerificationStatus
                  :id="accountVerificationObj.id"
                  :value="accountVerificationObj.profile[i.key]"
                  @update-account-verification-success="
                    $emit('account-verification-success')
                  "
                ></AccountVerificationStatus>
              </div>
              <div v-else>
                {{
                  accountVerificationObj.profile[i.key]
                    ? accountVerificationObj.profile[i.key]
                    : '-'
                }}
              </div>
            </div>
            <div v-else>
              {{
                accountVerificationObj[i.key]
                  ? accountVerificationObj[i.key]
                  : '-'
              }}
            </div>
          </div>
        </div>
      </template>
      <!-- begin: Company Unique Code -->
      <div
        v-if="
          ['talent_agency', 'production_corporate_agency'].includes(userRole)
        "
        class="mb-3 d-flex justify-content-between"
      >
        <div class="font-weight-bolder text-capitalize">
          {{ $t(`label.companyUniqueCode`) }}:
        </div>
        <div class="text-end text-gray-600">
          {{ accountVerificationObj.companyCode }}
        </div>
      </div>
      <!-- end: Company Unique Code -->
    </div>

    <b-list-group class="account-verification-details_item-list-group">
      <template v-for="(item, index) in accountVerificationDetailsItems">
        <b-list-group-item
          :key="index"
          button
          :active="value == item.key"
          @click="$emit('input', item.key)"
        >
          <b-row no-gutters>
            <b-col cols="2">
              <i class="icon mr-4" :class="item.icon"></i
            ></b-col>
            <b-col cols="auto" class="text-capitalize">
              {{ $t(`label.${item.name}`) }}</b-col
            >
          </b-row>
        </b-list-group-item>
      </template>
    </b-list-group>
    <ImageViewer
      v-if="showProfileImage"
      @onclose="showProfileImage = false"
      :value="profileImage"
    />
  </b-card>
</template>

<script>
  import AccountVerificationStatus from '@/views/components/account-verification/AccountVerificationStatus';
  import AppFeatureDetailsRelatedButton from '@/views/components/bases/AppFeatureDetailsRelatedButton';
  import { ROUTE_NAME } from '@/core/constants';
  import ImageViewer from '@/views/components/bases/ImageViewer.vue';

  export default {
    name: 'AccountVerificationOverviewCard',
    components: {
      AccountVerificationStatus,
      AppFeatureDetailsRelatedButton,
      ImageViewer
    },
    methods: {
      onProfileImageOpen() {
        this.showProfileImage = true;
      },
      onProfileImageClose() {
        this.showProfileImage = false;
      }
    },
    props: {
      value: {
        type: Number,
        default: 1
      },
      accountVerificationObj: {
        type: Object,
        default: () => {}
      },
      accountVerificationDetailsItems: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      showProfileImage: false,
      basicInfoList: [
        {
          key: 'contactNumber',
          name: 'contactNumber'
        },
        {
          key: 'email',
          name: 'email'
        },
        {
          key: 'verificationStatus',
          name: 'status',
          field: 'profile'
        },
        {
          key: 'verificationRemark',
          name: 'remark',
          field: 'profile'
        },
        {
          key: 'verificationSubmittedAt',
          name: 'submittedAt',
          field: 'profile'
        }
      ],
      routeName: ROUTE_NAME
    }),
    computed: {
      profileImage() {
        return (
          this?.accountVerificationObj?.profile?.headshotFile?.mediaPath || ''
        );
      },
      userRole() {
        return this.accountVerificationObj.role
          ? this.accountVerificationObj.role
              .toLowerCase()
              .replace(' ', '_')
              .replace('/', '_')
              .replace('/', '_')
          : null;
      }
    }
  };
</script>

<style lang="scss">
  .account-verification-details_item-list-group {
    .list-group-item.list-group-item-action.active {
      background: unset !important;
      border-color: unset !important;
      color: var(--primary);
      .icon {
        color: var(--primary);
      }
    }
  }
</style>
