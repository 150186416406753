<template>
  <div>
    <!--begin: Details Section-->
    <b-row no-gutters class="w-100 mt-4">
      <!--begin: Col 1-->
      <b-col cols="12" md="4">
        <!-- begin: Overview -->
        <AccountVerificationOverviewCard
          v-model="selectedAccountVerificationDetailsItem"
          :accountVerificationObj="accountVerificationObj"
          :accountVerificationDetailsItems="accountVerificationDetailsItems"
          class="mb-6"
          @account-verification-success="refreshAccountVerificationAction"
        ></AccountVerificationOverviewCard>
        <!-- begin: Overview -->
      </b-col>
      <!--end: Col 1-->

      <!--begin: Col 2-->
      <b-col cols="12" md="8" class="mt-4 mt-md-0 pl-md-4 pl-lg-8">
        <template v-for="(item, index) in accountVerificationDetailsItems">
          <component
            v-if="selectedAccountVerificationDetailsItem == item.key"
            :key="index"
            :is="item.component"
            :value="accountVerificationObj"
            :tableId="accountVerificationObj.profile.profileId"
            :logType="auditLogType"
            showAuditLogsCardHeader
          >
          </component>
        </template>
      </b-col>
      <!--end Col 2-->
    </b-row>
    <!--end: Details Section-->
  </div>
</template>

<script>
  import AccountVerificationOverviewCard from '@/views/components/account-verification/AccountVerificationOverviewCard';
  import AccountVerificationSupportingDocumentsCard from '@/views/components/account-verification/AccountVerificationSupportingDocumentsCard';
  import AuditLogsCard from '@/views/components/audit-logs/AuditLogsCard';
  import commonMixin from '@/core/mixins/common.mixin';
  import { ROUTE_NAME, MODULE, ACTION } from '@/core/constants';
  import { AUDIT_LOG_TABLE_NAME } from '@/core/constants/enums';
  import { i18nHelper } from '@/core/utils';
  import {
    USER_GET_ACCOUNT_VERIFICATION_DETAILS,
    USER_INITIAL_GET_ACCOUNT_VERIFICATION_DETAILS_STATE
  } from '@/core/store/user.module';

  export default {
    name: 'AccountVerificationDetails',
    mixins: [commonMixin],
    components: {
      AccountVerificationOverviewCard,
      AccountVerificationSupportingDocumentsCard,
      AuditLogsCard
    },
    data: () => ({
      routeName: ROUTE_NAME,
      moduleName: MODULE.USER,
      permissionAction: ACTION,
      id: null,
      accountVerificationObj: {
        profile: {}
      },
      selectedAccountVerificationDetailsItem: 1,
      accountVerificationDetailsItems: [
        {
          name: 'supportingDocuments',
          key: 1,
          icon: 'far fa-folder-open',
          component: 'AccountVerificationSupportingDocumentsCard'
        },
        {
          name: 'logs',
          key: 2,
          icon: 'flaticon2-list-3',
          component: 'AuditLogsCard'
        }
      ],
      auditLogType: AUDIT_LOG_TABLE_NAME.PROFILES
    }),
    computed: {
      accountVerificationDetailsComplete() {
        return this.$store.state.user.accountVerificationDetails.complete;
      }
    },
    watch: {
      accountVerificationDetailsComplete() {
        let response = this.$store.state.user.accountVerificationDetails;
        let title = i18nHelper.getMessage('label.getProfileDetails');
        let initialStateAction =
          USER_INITIAL_GET_ACCOUNT_VERIFICATION_DETAILS_STATE;
        let successAction = (response) => {
          this.importAccountVerificationObj(response);
        };

        if (response.complete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initialProfileDetailsDetails();
    },
    methods: {
      refreshAccountVerificationAction() {
        this.getAccountVerificationDetails(this.id);
      },
      importAccountVerificationObj(response) {
        let data = response.data;

        this.accountVerificationObj = data;
      },
      getAccountVerificationDetails(id) {
        this.$store.dispatch(USER_GET_ACCOUNT_VERIFICATION_DETAILS, {
          id
        });
      },
      initialProfileDetailsDetails() {
        let id = Number(this.$route.params.id);
        let breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.accountVerification'),
              route: { name: ROUTE_NAME.ACCOUNT_VERIFICATION }
            },
            { title: i18nHelper.getMessage('label.accountVerificationDetails') }
          ],
          actions: [
            {
              text: i18nHelper.getMessage('label.back'),
              function: () => {
                this.$router.go(-1);
              }
            }
          ]
        };

        this.id = id;
        this.getAccountVerificationDetails(id);
        this.initBreadCrumb(breadcrumbData);
        window.KTUtil.scrollTop();
      }
    }
  };
</script>

<style lang="scss"></style>
